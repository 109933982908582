
import { defineComponent } from "@vue/runtime-core";
import { useChecketouControl } from "./shared";

export default defineComponent({
  setup() {
    let control;
    try {
      control = useChecketouControl();
    } catch (e) {}

    return {
      pedido: control?.pedidoInput,
    };
  },
});
