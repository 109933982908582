<template>
  <div class="cart-totais p-col-12 p-d-flex p-jc-end">
    <div class="p-text-center p-p-2 p-text-center">
      <template v-if="pedido">
        <div v-if="!$store.getters.isCompraPa">
          <span>Pontuação dos produtos</span>
          <span>{{
            $formatNumber($store.state.carrinhoTotais.totalPontos)
          }}</span>
        </div>
        <div>
          <span>Valor dos produtos</span>
          <span>{{ $formatCurrency(pedido.totalItens) }}</span>
        </div>
        <template v-if="pedido.desconto">
          <div>
            <span>Desconto 1º pedido</span>
            <span>{{ $formatCurrency(pedido.desconto) }}</span>
          </div>
        </template>
        <template v-if="pedido.frete || pedido.entrega.tipoEntrega">
          <div>
            <span>Frete</span>
            <span> {{ $formatCurrency(pedido.frete || 0) }}</span>
          </div>
        </template>
        <div
          v-if="
            pedido.total !== pedido.totalItens || pedido.entrega.tipoEntrega
          "
        >
          <span>Total à pagar</span>
          <span>{{ $formatCurrency(pedido.total) }}</span>
        </div>
      </template>
      <template v-else>
        <div>
          <span>Valor dos produtos</span>
          <span>{{ $formatCurrency($store.state.carrinhoTotais.total) }}</span>
        </div>
        <div v-if="!$store.getters.isCompraPa">
          <span>Pontuação dos produtos</span>
          <span>{{
            $formatNumber($store.state.carrinhoTotais.totalPontos)
          }}</span>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "@vue/runtime-core";
import { useChecketouControl } from "./shared";

export default defineComponent({
  setup() {
    let control;
    try {
      control = useChecketouControl();
    } catch (e) {}

    return {
      pedido: control?.pedidoInput,
    };
  },
});
</script>

<style scoped lang="scss">
.cart-totais {
  padding: 0;
  > div {
    margin: 0.2em 0;
    background-color: #f9c851;
    font-weight: bold;
    font-size: 1.1em;
    line-height: 1.2em;
    max-width: 280px;
    width: 280px;
    border-radius: 5px;
    > div {
      display: flex;
      span:first-child {
        flex: 1;
        text-align: left;
      }
    }
  }
}
</style>