
import { getInstance, ItemCarrinho } from "@/services/CarrinhoService";
import { computed, defineComponent, onMounted, ref } from "vue";
import Totais from "../checkout/Totais.vue";
import { Item, getInstance as getServiceItem } from "@/services/ItemService";
import { useShowItem } from "@/components/ItemView.vue";
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";
import { useRouter } from "vue-router";
import { useStore } from "@/store";
import { ActionTypes } from "@/store/actions";
import { clone } from "@/utils/object";
import { formatCurrency } from "@/utils/format";

interface ItemCarrinhoWithLoading extends ItemCarrinho {
  loading?: boolean;
}

export default defineComponent({
  components: {
    Totais,
  },
  setup() {
    const started = ref(false);
    const store = useStore();
    const router = useRouter();
    const itens = ref<ItemCarrinhoWithLoading[]>([]);

    const perfil = computed(() => {
      return store.state.novoPerfil || store.state.user?.perfil;
    });

    const loading = ref(false);
    async function load() {
      loading.value = true;
      itens.value = [];
      try {
        await store.dispatch(ActionTypes.UPDATE_CARRINHO);
        itens.value = clone(store.state.carrinhoItens);
        // itens.value = (await getInstance().get()).itens;
      } finally {
        loading.value = false;
      }
    }

    onMounted(async () => {
      await store.dispatch(ActionTypes.LOAD_ME);
      if (!perfil.value) {
        router.push({ name: "change-perfil" });
        return;
      }

      load();
      started.value = true;
    });

    const itemView = useShowItem();

    const toast = useToast();
    const confirm = useConfirm();

    return {
      perfil,
      started,
      itemView,
      loading,
      itens,
      async clear() {
        confirm.require({
          message: "Tem certeza que deseja limpar o carrinho?",
          header: "Confirmação",
          icon: "pi pi-exclamation-triangle",
          async accept() {
            try {
              await getInstance().clear();
              load();
            } catch (e) {
              console.log("Falha ao limpar carrinho", e);
            }
          },
        });
      },
      async remover(item: ItemCarrinhoWithLoading) {
        confirm.require({
          message: "Tem certeza que deseja remover esse produto?",
          header: "Confirmação",
          icon: "pi pi-exclamation-triangle",
          async accept() {
            try {
              await getInstance().unsetItem(item);
              load();
            } catch (e) {
              console.log("Falha ao remover item do carrinho", e);
            }
          },
        });
      },
      async addItem(item: ItemCarrinhoWithLoading, qtd: number) {
        if (item.qtd === qtd) {
          return;
        }
        item.loading = true;
        try {
          await getInstance().addItem({
            itemId: item.itemId,
            qtd: qtd - item.qtd,
          });
          item.qtd = qtd;
          toast.add({
            severity: "success",
            summary: "Sucesso",
            detail: "Quantidade atualizada",
            life: 3000,
          });
        } finally {
          item.loading = false;
        }
      },
      fecharPedido() {
        const primeiroPedido =
          !store.state.user?.primeiroPedido || store.state.novoPerfil;
        if (
          primeiroPedido &&
          store.state.carrinhoTotais.total <
          (perfil.value.valorMinimo1pedido || 0)
        ) {
          const valMin = formatCurrency(perfil.value.valorMinimo1pedido || 0);
          toast.add({
            severity: "warn",
            detail: `O valor mínimo para o primeiro pedido é de ${valMin}`,
            life: 3000,
          });
          return;
        }
        if (
          primeiroPedido &&
          store.state.carrinhoTotais.totalPontos < (perfil.value.pp1pedido || 0)
        ) {
          const valMin = perfil.value.pp1pedido || 0;
          toast.add({
            severity: "warn",
            detail: `Este pedido deve ter no mínimo ${valMin} pontos de produto.`,
            life: 3000,
          });
          return;
        }
        if (
          !store.state.user?.ativado &&
          store.state.user?.expCiclo &&
          perfil.value.ppCiclo &&
          store.state.carrinhoTotais.totalPontos < perfil.value.ppCiclo
        ) {
          toast.add({
            severity: "warn",
            detail: `Para ativação mensal o pedido deve ter no mínimo ${perfil.value.ppCiclo} pontos`,
            life: 3000,
          });
          return;
        }
        if (
          store.getters.isCompraPa &&
          store.state.carrinhoTotais.total < 100
        ) {
          toast.add({
            severity: "warn",
            detail: "O valor de recompra mínima para ponto de apoio é de R$ 100,00",
            life: 3000,
          });
          return;
        }
        router.push({ name: "checkout" });
      },
      voltar() {
        router.push({ name: "loja" });
      },
    };
  },
  methods: {
    getUrlImagem(item?: Item) {
      return (
        (item && getServiceItem().getUrlImagem(item)) ||
        require("@/assets/shop-placeholder.png")
      );
    },
  },
});
