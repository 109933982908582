import { client } from "@/api/apollo";
import { TipoEntrega } from "@/services/TipoEntregaService";
import { PessoaEndereco } from "@/services/PessoaService";
import { reactive, Ref, ref } from "@vue/reactivity";
import { inject, nextTick, onMounted, provide, watch } from "@vue/runtime-core";
import gql from "graphql-tag";
import {
  CalcTipoEntrega,
  getInstance as getPedidoService,
  initialPedidoInput,
  PedidoInput,
  PedidoItemInput,
} from "@/services/PedidoService";
import { clone } from "@/utils/object";
import { calcValorAfiliado } from "@/services/ItemService";
import { store } from "@/store";
import { ActionTypes } from "@/store/actions";

const CHECKOUT_CONTROL = Symbol();

interface CheckoutControl {
  loading: Ref<boolean>;
  loadingItensCarrinho: Ref<Boolean>;
  pedidoInput: PedidoInput;
  endereco: Ref<PessoaEndereco | undefined>;
}

export function installControl(): CheckoutControl {
  const loadingItensCarrinho = ref(false);
  async function load() {
    loadingItensCarrinho.value = true;
    try {
      await store.dispatch(ActionTypes.UPDATE_CARRINHO);
    } finally {
      loadingItensCarrinho.value = false;
    }

    await nextTick();
    calcTotaisPedido(pedidoInput);
  }
  onMounted(load);

  const loading = ref(false);
  const pedidoInput = reactive<PedidoInput>(clone(initialPedidoInput));

  const endereco = ref<PessoaEndereco>();
  watch(endereco, () => {
    pedidoInput.entrega = clone(initialPedidoInput.entrega);
  });

  watch(() => pedidoInput.entrega.valor, () => {
    calcTotaisPedido(pedidoInput);
  });

  const control = {
    loadingItensCarrinho,
    loading,
    pedidoInput,
    endereco,
  };
  provide<CheckoutControl>(CHECKOUT_CONTROL, control);

  return control;
}

export function useChecketouControl(): CheckoutControl {
  const control = inject<CheckoutControl | null>(CHECKOUT_CONTROL, null);
  if (!control) {
    throw new Error("Provide CHECKOUT_CONTROL not found");
  }
  return control;
}

interface InfoCheckout {
  tipoEntregas: TipoEntrega[];
}

export async function getInfoCheckout(): Promise<InfoCheckout> {
  const result = await client
    .query({
      query: gql`
        query {
          tipoEntregas(
            first: 10
            somenteAtivos: true
            orderBy: {
              column: "descricao"
              order: ASC
            }
          ) {
            data {
              id
              descricao
              status
              transportadora
            }
          }
        }
      `,
    });
  const { data: { tipoEntregas: { data } } } = result;
  return {
    tipoEntregas: data,
  };
}

export async function calcTipoEntregas(
  cep: string,
): Promise<CalcTipoEntrega[]> {
  const result = await client
    .query({
      variables: { cep },
      query: gql`
        query ($cep: String!) {
          calcTipoEntregas(cep: $cep) {
            tipoEntrega {
              id
              retirarLocal
            }
            tipoEntregaId
            valor
            prazo
            erro
            servico
            prazoMin
            compensarFrete
            compfreteCompraMinAfiliado
            compfreteCompraMinPa
          }
        }
      `,
    });
  const { data: { calcTipoEntregas } } = result;
  return calcTipoEntregas;
}

export async function fecharPedido(control: CheckoutControl): Promise<number> {
  const carrinhoItens = store.state.carrinhoItens;
  if (!carrinhoItens.length) {
    return null!;
  }
  const totalItens = carrinhoItens.reduce((tot, i) => {
    const valor = calcValorAfiliado(i.item!);
    return tot + (i.qtd * valor);
  }, 0);
  const desconto = control.pedidoInput.desconto || 0;
  const frete = control.pedidoInput.entrega.valor;
  const total = totalItens + frete - desconto;

  const itens = carrinhoItens.map((i) => {
    const valor = calcValorAfiliado(i.item!);
    return {
      // @ts-ignore
      itemId: parseInt(i.itemId),
      qtd: i.qtd,
      valor,
      total: i.qtd * valor,
    } as unknown as PedidoItemInput;
  });

  return await getPedidoService().criarPedido({
    itens,
    observacao: control.pedidoInput.observacao,
    desconto,
    frete,
    total,
    totalItens,
    enderecoId: control.endereco.value?.id || null!,
    fromCart: true,
    entrega: control.pedidoInput.entrega,
    perfilId: store.state.novoPerfil?.id || null!,
  });
}

function calcTotaisPedido(p: PedidoInput) {
  p.totalItens = store.state.carrinhoTotais.total;
  p.desconto = 0;

  const perfil = store.state.novoPerfil || store.state.user?.perfil;
  const primeiroPedido = !store.state.user?.primeiroPedido ||
    store.state.novoPerfil;
  if (
    primeiroPedido &&
    perfil?.pp1pedido &&
    perfil?.desconto1pedido &&
    store.state.carrinhoTotais.totalPontos > perfil.pp1pedido
  ) {
    const valorMinimo = perfil.valorMinimo1pedido || 0;

    const baseCalculo = getBaseCalculo1pedido();
    if (baseCalculo > valorMinimo) {
      const DIF_VALOR = (baseCalculo - valorMinimo);
      const INDICE = DIF_VALOR / (100 - perfil.desconto1pedido);
      const DESCONTO = DIF_VALOR - (INDICE * (100 - 50));
      p.desconto = DESCONTO;
    }

    // const diff = store.state.carrinhoTotais.totalPontos - perfil.pp1pedido;
    // const valorPassouPontosMinimos = p.totalItens * diff /
    //   store.state.carrinhoTotais.totalPontos;
    // TODO: calcular o desconto de forma dinamica
    // p.desconto = valorPassouPontosMinimos * (10 / 100);
  }

  p.frete = p.entrega ? p.entrega.valor : 0;
  p.total = p.totalItens + p.frete - (p.desconto);
}

function getBaseCalculo1pedido() {
  const carrinhoItens = store.state.carrinhoItens;
  return carrinhoItens.filter((i) => i.item?.categoria.descontoNo1pedido)
    .reduce((tot, i) => {
      const valor = calcValorAfiliado(i.item!);
      return tot + (i.qtd * valor);
    }, 0);
}
