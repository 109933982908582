<template>
  <div class="p-grid card" v-if="!started">
    <p-progress-spinner />
  </div>
  <div class="p-grid card" v-else>
    <x-mensagem-ativacao />

    <h5
      class="p-d-flex p-flex-column p-flex-sm-row p-ai-center"
      style="width: 100%"
    >
      <span style="flex: 1; width: 100%; min-width: 200px" class="p-mb-2">
        Meu Carrinho
        <p-progress-spinner v-if="loading" style="width: 15px; height: 15px" />
      </span>
      <div class="p-text-right" style="width: 100%">
        <p-button
          class="p-button-secondary p-mr-2"
          label="Voltar"
          @click="voltar"
        />
        <p-button label="Limpar" @click="clear" class="p-button-danger p-mr-2" />
        <p-button
          label="Fechar Pedido"
          @click="fecharPedido"
          :disabled="!itens.length"
        />
      </div>
    </h5>

    <Totais />

    <div v-for="i in itens" :key="i.id" class="cart-item">
      <x-item-card
        v-if="i.item"
        :item="i.item"
        :initialQtd="i.qtd"
        labelAdicionar="Alterar"
        showRemover
        :showAdicionar="false"
        :showStatus="false"
        :showTotal="true"
        :loadingTotal="i.loading"
        @changeQtd="(qtd) => addItem(i, qtd)"
        @show="i.item && itemView.show(i.item.id)"
        @remover="remover(i)"
      ></x-item-card>
    </div>

    <template v-if="!loading && itens.length">
      <p-divider />
      <Totais />

      <h5 class="p-d-flex p-ai-center p-jc-end" style="width: 100%">
        <p-button
          class="p-button-secondary p-mr-2"
          label="Voltar"
          @click="voltar"
        />
        <p-button label="Limpar" @click="clear" class="p-button-danger p-mr-2" />
        <p-button
          label="Fechar Pedido"
          @click="fecharPedido"
          :disabled="!itens.length"
        />
      </h5>
    </template>
  </div>

  <x-item-view
    v-model:visible="itemView.visible"
    :title="itemView.title"
    :id="itemView.id"
  ></x-item-view>
</template>

<script lang="ts">
import { getInstance, ItemCarrinho } from "@/services/CarrinhoService";
import { computed, defineComponent, onMounted, ref } from "vue";
import Totais from "../checkout/Totais.vue";
import { Item, getInstance as getServiceItem } from "@/services/ItemService";
import { useShowItem } from "@/components/ItemView.vue";
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";
import { useRouter } from "vue-router";
import { useStore } from "@/store";
import { ActionTypes } from "@/store/actions";
import { clone } from "@/utils/object";
import { formatCurrency } from "@/utils/format";

interface ItemCarrinhoWithLoading extends ItemCarrinho {
  loading?: boolean;
}

export default defineComponent({
  components: {
    Totais,
  },
  setup() {
    const started = ref(false);
    const store = useStore();
    const router = useRouter();
    const itens = ref<ItemCarrinhoWithLoading[]>([]);

    const perfil = computed(() => {
      return store.state.novoPerfil || store.state.user?.perfil;
    });

    const loading = ref(false);
    async function load() {
      loading.value = true;
      itens.value = [];
      try {
        await store.dispatch(ActionTypes.UPDATE_CARRINHO);
        itens.value = clone(store.state.carrinhoItens);
        // itens.value = (await getInstance().get()).itens;
      } finally {
        loading.value = false;
      }
    }

    onMounted(async () => {
      await store.dispatch(ActionTypes.LOAD_ME);
      if (!perfil.value) {
        router.push({ name: "change-perfil" });
        return;
      }

      load();
      started.value = true;
    });

    const itemView = useShowItem();

    const toast = useToast();
    const confirm = useConfirm();

    return {
      perfil,
      started,
      itemView,
      loading,
      itens,
      async clear() {
        confirm.require({
          message: "Tem certeza que deseja limpar o carrinho?",
          header: "Confirmação",
          icon: "pi pi-exclamation-triangle",
          async accept() {
            try {
              await getInstance().clear();
              load();
            } catch (e) {
              console.log("Falha ao limpar carrinho", e);
            }
          },
        });
      },
      async remover(item: ItemCarrinhoWithLoading) {
        confirm.require({
          message: "Tem certeza que deseja remover esse produto?",
          header: "Confirmação",
          icon: "pi pi-exclamation-triangle",
          async accept() {
            try {
              await getInstance().unsetItem(item);
              load();
            } catch (e) {
              console.log("Falha ao remover item do carrinho", e);
            }
          },
        });
      },
      async addItem(item: ItemCarrinhoWithLoading, qtd: number) {
        if (item.qtd === qtd) {
          return;
        }
        item.loading = true;
        try {
          await getInstance().addItem({
            itemId: item.itemId,
            qtd: qtd - item.qtd,
          });
          item.qtd = qtd;
          toast.add({
            severity: "success",
            summary: "Sucesso",
            detail: "Quantidade atualizada",
            life: 3000,
          });
        } finally {
          item.loading = false;
        }
      },
      fecharPedido() {
        const primeiroPedido =
          !store.state.user?.primeiroPedido || store.state.novoPerfil;
        if (
          primeiroPedido &&
          store.state.carrinhoTotais.total <
          (perfil.value.valorMinimo1pedido || 0)
        ) {
          const valMin = formatCurrency(perfil.value.valorMinimo1pedido || 0);
          toast.add({
            severity: "warn",
            detail: `O valor mínimo para o primeiro pedido é de ${valMin}`,
            life: 3000,
          });
          return;
        }
        if (
          primeiroPedido &&
          store.state.carrinhoTotais.totalPontos < (perfil.value.pp1pedido || 0)
        ) {
          const valMin = perfil.value.pp1pedido || 0;
          toast.add({
            severity: "warn",
            detail: `Este pedido deve ter no mínimo ${valMin} pontos de produto.`,
            life: 3000,
          });
          return;
        }
        if (
          !store.state.user?.ativado &&
          store.state.user?.expCiclo &&
          perfil.value.ppCiclo &&
          store.state.carrinhoTotais.totalPontos < perfil.value.ppCiclo
        ) {
          toast.add({
            severity: "warn",
            detail: `Para ativação mensal o pedido deve ter no mínimo ${perfil.value.ppCiclo} pontos`,
            life: 3000,
          });
          return;
        }
        if (
          store.getters.isCompraPa &&
          store.state.carrinhoTotais.total < 100
        ) {
          toast.add({
            severity: "warn",
            detail: "O valor de recompra mínima para ponto de apoio é de R$ 100,00",
            life: 3000,
          });
          return;
        }
        router.push({ name: "checkout" });
      },
      voltar() {
        router.push({ name: "loja" });
      },
    };
  },
  methods: {
    getUrlImagem(item?: Item) {
      return (
        (item && getServiceItem().getUrlImagem(item)) ||
        require("@/assets/shop-placeholder.png")
      );
    },
  },
});
</script>

<style scoped lang="scss">
.cart-item {
  width: 100%;
  > div {
    border: solid #e9ecef;
    border-width: 2px 0 0;
  }
}
</style>
